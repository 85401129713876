import React, { useEffect, useState } from "react";
import { Card, Input, Modal, Table, Tooltip } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getLeaveApprovalPagination, handleApprovedStatusById } from "hooks/api/payrollApi/leaveApprovalApi";
import { PAYROLL_LEAVE_APPROVAL } from "constants";
import PayrollFilter from 'pages/payroll/PayRollFilters';
import { getEmployeeLeavePolicy } from "hooks/api/masterApi/employeeLeavePolicyApi";
import AddEditLeaveApproval from './EditLeaveApproval';
import { EmployeeDetails } from "components/common/CommonComponent";
import { useNavigate } from "react-router-dom";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import dayjs from 'dayjs';
import MyButton from "components/ui/Button/MyButton";

const initialData = {
    from_date: "",
    to_date: "",
    leave_type: "",
    employee: "",
    leaveTypeList: [],
}

function LeaveApproval() {
    const authtoken = sessionStorage.getItem("token");
    const query = "";
    const pageSize = 10;
    const [editVisible, setEditVisible] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState({});
    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const [isReasonPopup, setReasonPopup] = useState(false);
    const [updatedStatus, setUpdatedStatus] = useState(null);
    const companyid = sessionStorage.getItem("userId");
    const tableContainerHeight = "72vh";
    const dateFormat = "YYYY-MM-DD";

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const navigate = useNavigate();

    const userPermission = checkUserPermissionFunc("employee", "Approval Inbox", "SubMenu");

    const [data, pageInfo, loading, _message, refetch] = useApiData(getLeaveApprovalPagination, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });

    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);


    const handleSave = () => {
        setEditVisible(false);
        setSelectedDetails({});
        refetch(pageNo,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
        setReasonPopup(false)
    };

    const handleCancel = () => {
        setEditVisible(false);
        setSelectedDetails({});
        setReasonPopup(false)
    };


    const getLeaveTypeList = async () => {
        try {
            const apiData = await getEmployeeLeavePolicy(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    leaveTypeList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    leaveTypeList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                ...prevFilterData,
                leaveTypeList: []
            }))
        }
    }

    useEffect(() => {
        getLeaveTypeList();
    }, []);

    const handleEditClick = (details) => {
        setSelectedDetails(details);
        setEditVisible(true);
    };

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `leave_id=${data.leave_type ? data.leave_type : ""}&from_date=${data.from_date ? data.from_date : ""}&to_date=${data.to_date ? data.to_date : ""}&employee=${data.employee ? data.employee : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas((prevState) => ({
            ...prevState,
            from_date: "",
            to_date: "",
            leave_type: "",
            employee: "",
          }));
          setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handleSearch = () => {
        setSelectedDetails({});
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const handleApproveStatus = (record, status) => {
        let updatedSataus = {
            id: record.id,
            approval_status: status,
            company_id: companyid,
            from_date: dayjs(record.from_date).format(dateFormat),
            to_date: dayjs(record.to_date).format(dateFormat),
            leave_id: record?.id,
            leave_type: record?.leave_type_id,
            reason: record?.reason,
            uploaded_documents: []
        };
        setReasonPopup(true)
        setUpdatedStatus(updatedSataus)
    }

    const handleSubmit = () => {
        handleApprovedStatusById(updatedStatus).then((data) => {
            if (data.status === "success") {
                ToastMsg("success", data.message);
                handleSave();
            } else if (data.status === "fail") {
                ToastMsg("error", data.message);
                handleSave();
            }
        })
    }
    
    const handleDescriptionPopup = () => {
        return (
            <Modal
                centered
                open={isReasonPopup}
                onCancel={handleCancel}
                footer={null}
                width={"30vw"}
            >
                <Input.TextArea
                    autoComplete='off'
                    bordered="true"
                    className="mt-7 textarea"
                    rows={4}
                    autoSize={{
                        minRows: 3,
                        maxRows: 4,
                    }}
                    maxLength={150}
                    placeholder="Enter reason for Approval Notes for a leave Approval"
                    name="approval_notes"
                    onChange={(e) => {
                        setUpdatedStatus({
                            ...updatedStatus,
                            approval_notes: e.target.value,
                        });
                    }}
                />
                <div className='flex justify-end items-end mt-3'>
                    <MyButton htmlType="button" label={"Cancel"} onClick={handleCancel} loading={loading} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID='asgreg_btn_draft' />
                    <MyButton htmlType="button" label={updatedStatus?.approval_status === "rejected" ? "Reject" : "Approve"} onClick={handleSubmit} loading={loading} paddingX={"0 1.2vw"} bgColor={updatedStatus?.approval_status === "rejected" ? "#D94854" : "#0E862A"} />
                </div>
            </Modal>
        )
    }

    const columns = [
        {
            title: "From Date",
            dataIndex: "from_date",
            key: "from_date",
            sorter: true,
            width: "15%",
            render: (_, record) =>
                <p>
                    {record?.from_date ? dayjs(record?.from_date).format('DD/MM/YYYY') : "-"}
                </p>,
            align: "center",
            fixed: "left",
        },
        {
            title: "To Date",
            dataIndex: "to_date",
            key: "to_date",
            sorter: true,
            width: "15%",
            render: (_, record) =>
                <p>
                    {record?.to_date ? dayjs(record?.to_date).format('DD/MM/YYYY') : "-"}
                </p>,
            align: "center",
            fixed: "left",
        },
        {
            title: "Approval Sent from",
            dataIndex: "emp_detail",
            key: "employee__employee_name",
            sorter: true,
            width: "20%",
            fixed: "left",
            render: (_, record) => {
                let empDetails = {
                    ...record.employee,
                    "designation_id": record?.employee.designation ? { id: record?.employee.designation.id, designation_name: record?.employee.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.employee.employee_name ? record?.employee.employee_name : "",
                    "id": record?.employee.employee_id
                }
                return (
                    <span style={{ color: record.is_active ? "black" : "#CACACA", cursor: "pointer" }}>
                        <EmployeeDetails details={empDetails} />
                    </span>
                )
            }
        },
        {
            title: "Leave Type",
            dataIndex: "leave_type",
            key: "leave_type__name",
            sorter: true,
            width: "18%",
            fixed: "left",
            render: (_, record) =>
            (record?.notes?.length > 20 ?
                <Tooltip title={record?.leave_type.name}>{<span>{record?.leave_type.name?.slice(0, 20) + "..."}</span>}</Tooltip>
                : <p>{record?.leave_type.name ? record?.leave_type.name : "-"}</p>)
        },
        {
            title: "Request Type",
            dataIndex: "request_type",
            key: "request_type_name",
            sorter: true,
            width: "18%",
            fixed: "left",
            render: (_, record) =>
            (record?.notes?.length > 20 ?
                <Tooltip style={{textTransform: "capitalize"}} title={record?.request_type}>{<span>{record?.request_type?.slice(0, 20) + "..."}</span>}</Tooltip>
                : <p style={{textTransform:"capitalize"}}>{record?.request_type ? record?.request_type : "-"}</p>)
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            sorter: true,
            width: "20%",
            fixed: "left",
            render: (_, record) =>
            (record?.reason?.length > 20 ?
                <Tooltip title={record?.reason}>{<span>{record?.reason?.slice(0, 20) + "..."}</span>}</Tooltip>
                : <p>{record?.reason ? record?.reason : "-"}</p>)
        },
        {
            title: "Action",
            key: "action",
            align: 'center',
            width: "15%",
            fixed: "left",
            render: (_, record) => (
                <div className="flex flex-row justify-center items-center">
                    <IconButton
                        className="mr-3"
                        title="Request Details"
                        icon={<img src={ImagePaths.ApproveIcon.default} alt="Request Details" />}
                        onClick={() => (loading ? "" : handleEditClick(record))}
                    />
                       {/* To handle the approval, we added a necessary option for approval fields, such that the reason it was hidden */}
                       {/* {!userPermission?.edit ?  
                       <>
                            <IconButton
                                className="mr-3"
                                title="Approve"
                                icon={<img src={ImagePaths.circleRight.default} alt="Approve" />}
                                onClick={() => (loading ? "" : handleApproveStatus(record, (record?.request_type === "leave request" ? "approved" : "cancelled")))}
                            />
                            <IconButton
                                title="Reject"
                                icon={<img src={ImagePaths.circleWrong.default} alt="Reject" />}
                                onClick={() => (loading ? "" : handleApproveStatus(record, "rejected"))}
                            />
                       </> : null} */}
                </div>
            ),
        },
    ];

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleBackToHome = () => {
        navigate('/employee/approvalinbox');
        refetch(pageNo,
            pageSize,
            query,
            searchText,
            sorting);
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&leave_id=" + (filterDatas.leave_type ? filterDatas.leave_type : "") +
        "&from_date=" + (filterDatas.from_date ? filterDatas.from_date : "") +
        "&to_date=" + (filterDatas.to_date ? filterDatas.to_date : "") +
        "&employee=" + (filterDatas.employee ? filterDatas.employee : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");;

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"Leave Approval"}
                    isAddRequired={false}
                    pageInfo={pageInfo} handleSearch={handleSearch} selectedDetails={selectedDetails}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    handleBackToHome={handleBackToHome} isDraft={false}
                    url={PAYROLL_LEAVE_APPROVAL} query={queryString} isBackRequired={true} isDraftRequired={false}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <PayrollFilter
                            pageName={"LeaveApproval"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                <Table 
                    columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                    dataSource={data?.length > 0 ? data : null}
                    loading={loading}
                    style = {{"--table-container-height": tableContainerHeight}}
                    scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                    onChange={handleTableChange}
                    pagination={{
                        style: { marginRight: 20, padding: 0 },
                        defaultPageSize: pageSize,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                        pageSizeOptions: ["10", "20", "30"],
                        pageSize,
                        defaultCurrent: pageNo,
                    }}
                    key={dataUpdated ? "updated" : "not-updated"}
                />
                </div>
            </div>
            {isReasonPopup ? handleDescriptionPopup() : null}
            {(editVisible && Object.keys(selectedDetails).length > 0) ?
                <AddEditLeaveApproval
                    editDetails={selectedDetails}
                    onHide={handleCancel}
                    onSave={handleSave}
                    fromEdit={editVisible}
                    leaveTypeList={filterDatas?.leaveTypeList?.length > 0 ? filterDatas?.leaveTypeList : []}
                    isEdit = {userPermission?.edit}
                />
                : null}
        </Card>
    );
}

export default LeaveApproval;