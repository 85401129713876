import React, { useEffect, useState } from "react";
import { Card, Table, Tooltip } from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import useApiData from "components/common/useGetApiData";
import { getResignationApproval } from "hooks/api/payrollApi/resignationApprovalApi";
import { EMPLOYEE_RESIGNATION_APPROVAL } from "constants";
import PayrollFilter from 'pages/payroll/PayRollFilters';
import AddEditResignationApproval from './EditResignationApproval'
import { EmployeeDetails } from "components/common/CommonComponent";
import { useNavigate } from "react-router-dom";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import dayjs from 'dayjs';

const initialData = {
    requested_date: "",
    department_name: "",
    employee: "",
    departmentList:[],
}

function PayrollResignation() {
    const authtoken = sessionStorage.getItem("token");
    const query = "";
    const pageSize = 10;
    const [editVisible, setEditVisible] = useState(false);
    const [selectedDetails, setSelectedDetails] = useState({});
    const [searchText, setSearchText] = useState("");
    const [dataUpdated, setDataUpdated] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [sorting, setSorting] = useState("");
    const [filterVisible, setFilterVisible] = useState(false);
    const [filterDatas, setFilterDatas] = useState({ initialData });
    const [reorderedColumns, setReorderedColumns] = useState([]);
    const tableContainerHeight = "72vh";

    useEffect(() => {
        if (!authtoken) {
            window.location.href = "/";
        }
    }, [authtoken]);

    const navigate = useNavigate();

    const userPermission = checkUserPermissionFunc("Employee", "Approval Inbox", "SubMenu");

    const [data, pageInfo, loading, _message, refetch] = useApiData(getResignationApproval, {
        pageNo,
        pageSize,
        query,
        searchText,
        sorting,
    });
    useEffect(() => {
        if (_message && _message.isErrorMsg && _message.errorMsgText) {
            ToastMsg('error', _message.errorMsgText);
        }
    }, [_message.errorMsgText]);

    useEffect(() => {
        getDepartmentList();
    }, []);
    
    /*call departments*/
    const getDepartmentList = async () => {
        try {
            const apiData = await getEmployeeAllDepartment(`drop_down=True`);
            if (apiData && apiData.status === "success" && apiData.data) {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    departmentList: [...apiData.data]
                }))
            } else {
                setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    leaveTypeList: []
                }))
            }
        } catch (error) {
            setFilterDatas((prevFilterData) => ({
                    ...prevFilterData,
                    leaveTypeList: []
                }))
        }
    }

    const handleEditClick = (details) => {
        navigate('/employee/approvalinbox/resignationRequest', { state: { editDetails: details } });
        setSelectedDetails(details);
        setEditVisible(true);
    };

    // filter start
    const renderFilter = (data) => {
        setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
        setPageNo(1);
        refetch(1,
            pageSize,
            `requested_date=${data.requested_date ? data.requested_date : ""}&department=${data.department_name ? data.department_name : ""}&employee=${data.employee ? data.employee : ""}`,
            searchText,
            sorting);
        setFilterVisible(false);
    }

    const ClosePopUp = () => {
        setFilterVisible(false);
    }

    const clearFilter = () => {
        setFilterDatas((prevState) => ({
            ...prevState,
            requested_date: "",
            department_name: "",
            employee: "",
          }));
          setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
    }
    //filter end

    const handleSearch = () => {
        setSelectedDetails({});
        setPageNo(1);
        refetch(1,
            pageSize,
            query,
            searchText,
            sorting);
        setDataUpdated(!dataUpdated);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
        const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
        refetch(pagination.current,
            pageSize,
            query,
            searchText,
            sortQuery);
        setSorting(sortQuery)
    }

    const columns = [
        {
            title: "Employee Details",
            dataIndex: "emp_detail",
            key: "employee__employee_name",
            sorter: true,
            width: "25%",
            fixed: "left",
            render: (_, record) => {
                let empDetails = {
                    ...record.employee_details,
                    "designation_id": record?.employee_details.designation ? { id: record?.employee_details.designation.id, designation_name: record?.employee_details.designation.name } : { id: 0, designation_name: "" },
                    "first_name": record?.employee_details.employee_name ? record?.employee_details.employee_name : "",
                    "id": record?.employee_details.employee_id
                }
                return (
                    <span style={{ color: record.is_active ? "black" : "#CACACA", cursor: "pointer" }}>
                        <EmployeeDetails details={empDetails} />
                    </span>
                )
            }
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            sorter: true,
            width: "20%",
            fixed: "left",
            render: (_, record) => {
              return (record.employee_details?.department?.name?.length > 20 ?
                <Tooltip title={record.employee_details?.department?.name}>{<span>{record.employee_details?.department?.name?.slice(0, 20) + "..."}</span>}</Tooltip>
                : <p>{record.employee_details?.department?.name ? record.employee_details?.department?.name : "-"}</p>)
            }
          },
        {
            title: "Request Date",
            dataIndex: "request_date",
            key: "request_date",
            sorter: true,
            width: "15%",
            render: (_, record) =>
                <p>
                    {record?.request_date ? dayjs(record?.request_date).format('DD/MM/YYYY') : "-"}
                </p>,
            align: "center",
            fixed: "left",
        },
        {
            title: "Reason For Resignation",
            dataIndex: "reason",
            key: "reason",
            sorter: true,
            width: "30%",
            fixed: "left",
            render: (_, record) =>
            (record?.reason?.length > 40 ?
                <Tooltip title={record?.reason}>{<span>{record?.reason?.slice(0, 40) + "..."}</span>}</Tooltip>
                : <p>{record?.reason ? record?.reason : "-"}</p>)
        },
        {
            title: "Action",
            key: "action",
            align: 'center',
            width: "10%",
            fixed: "left",
            render: (_, record) => (
                <div className="flex flex-row justify-center items-center">
                    {userPermission?.edit ? 
                        <IconButton
                            className="mr-3"
                            title="Approval"
                            icon={<img src={ImagePaths.ApproveIcon.default} alt="View" />}
                            onClick={() => (loading ? "" : handleEditClick(record))}
                        />
                        : null }
                </div>
            ),
        },
    ];

    const handleFilter = () => {
        setFilterVisible(true)
    }

    const handleBackToHome = () => {
        navigate('/employee/approvalinbox');
        refetch(pageNo,
            pageSize,
            query,
            searchText,
            sorting);
    }

    const handleStateChanges = (search = "", reorderedList = []) => {
        setSearchText(search)
        setReorderedColumns(reorderedList)
    }

    const queryString =
        "?page_no=" + pageNo +
        "&page_size=" + pageSize +
        "&requested_date=" + (filterDatas.requested_date ? filterDatas.requested_date : "") +
        "&department=" + (filterDatas.department_name ? filterDatas.department_name : "") +
        "&employee=" + (filterDatas.employee ? filterDatas.employee : "") +
        "&search=" + searchText + (sorting ? `&${sorting}` : "");;

    const styles = {
        fltContainer: {
            border: "1px solid #cbcbcb",
            height: tableContainerHeight,
        },
    }

    return (
        <Card className="h-full overflow-hidden">
            <div className={"h-1/6"} >
                <CommonTblHeader pageName={"ResignationApproval"}
                    isAddRequired={false}
                    pageInfo={pageInfo} handleSearch={handleSearch} selectedDetails={selectedDetails}
                    onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
                    handleBackToHome={handleBackToHome} isDraft={false}
                    url={EMPLOYEE_RESIGNATION_APPROVAL} query={queryString} isBackRequired={true} isDraftRequired={false}
                />
            </div>
            <div className="h-5/6 flex justify-center items-center mt-1">
                {filterVisible ?
                    <div className="w-1/4 mt-5" style={styles.fltContainer}>
                        <PayrollFilter
                            pageName={"ResignationApproval"}
                            filterDatas={filterDatas}
                            closeFilter={ClosePopUp}
                            filteredData={renderFilter}
                            clearData={clearFilter} />
                    </div>
                    : null}
                <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
                <Table 
                    columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
                    dataSource={data?.length > 0 ? data : null}
                    loading={loading}
                    style = {{"--table-container-height": tableContainerHeight}}
                    scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
                    onChange={handleTableChange}
                    pagination={{
                        style: { marginRight: 20, padding: 0 },
                        defaultPageSize: pageSize,
                        showSizeChanger: false,
                        showQuickJumper: true,
                        total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                        pageSizeOptions: ["10", "20", "30"],
                        pageSize,
                        defaultCurrent: pageNo,
                    }}
                    key={dataUpdated ? "updated" : "not-updated"}
                />
                </div>
            </div>
        </Card>
    );
}

export default PayrollResignation;