import { Button, Card, DatePicker, Upload, Checkbox, Tooltip } from "antd";
import React, { useRef, useEffect, useState } from "react";
import { Form, Input, Select, Spin, Modal } from "antd";
import dayjs from "dayjs";
import { MinusCircleOutlined, PlusCircleOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import { letterStatusEnum } from "components/common/enum";
import {
  extractImageName,
  extractFileExtension,
  disabledPastDate
} from "components/common/validation";
import { ImagePaths } from "utils/ImagePath";
import ToastMsg from "components/common/ToastMsg";
import { imgUploadSize, verifyStatusEnum } from 'components/common/enum';
import { getEmployee } from "hooks/api/employeeApi/employeeInformationApi";
import locale from 'antd/es/date-picker/locale/en_US';
import { LETTER_TYPE_API } from "constants";
import { getMethod } from 'components/common/CommonFuntion'
import { prefillUrl } from 'utils/StringUtils';
import IconButton from "components/ui/Button/IconButton";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadPDF } from "components/common/CommonFuntion";

export const EmpOnboardingFinalStep = ({
  formData,
  updateFormData,
  updateWholeFormData,
  onSave,
  OnBack,
  isLoading,
  isScrLoading,
  fromEdit,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const hideCursorRef = useRef([]);
  const [selectedLevels, setselectedLevels] = useState([]);
  const [preview, setPreview] = useState({
    visible: false,
    content: null,
    type: '', // 'image' or 'pdf'
    contentType: '',
  });
  const [existingFiles, setExistingFiles] = useState([]);
  const [empList, setempList] = useState([]);
  const [letterTypeApiList, setLetterTypeApiList] = useState([]);
  const [disabled, setIsDisable] = useState(false);

  const is_super_admin = JSON.parse(sessionStorage.getItem("is_super_admin"));
  const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));

  let isEditAccess = (is_super_admin || is_admin);

  useEffect(() => {
    if (Object.entries(formData).length > 0 &&
      formData !== undefined) {
      form.setFieldsValue({
        ...formData,
      })
    }
  }, [form, formData]);

  useEffect(() => {
    setIsDisable(false)
  },[isLoading])

  //api calls starts
  const employee = async () => {
    setempList([])
    try {
      const apiData = await getEmployee(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setempList([...apiData.data])
      } else {
        setempList([])
      }
    } catch (error) {
      setempList([])
    }
  };

  const getLetterTypeApi = async () => {
    try {
      let url = prefillUrl(LETTER_TYPE_API);
      url = `${url}${`drop_down=True`}`;

      const apiData = await getMethod(url);
      if (apiData && apiData.status === "success" && apiData.data) {
        const filteredData = apiData.data.filter(item => item.process_type_name === "on_bording");
        setLetterTypeApiList([...filteredData]);
      } else {
        setLetterTypeApiList([]);
      }
    } catch (error) {
      setLetterTypeApiList([]);
    }
  }

  useEffect(() => {
    employee();
    getLetterTypeApi();
  }, []);
  // api calls ends


  const checkForOnboardDocumentDuplicates = (documents) => {
    const encounteredLetterType = new Set();

    for (const doc of documents) {
      const { letter_type } = doc;

      // Check for duplicates for letter_type
      if (letter_type) {
        if (encounteredLetterType.has(letter_type)) {
          return "Same Letter Type Found";
        }
        encounteredLetterType.add(letter_type);
      }
    };
    return "";
  }

  const handleAdd = () => {
    const allPreviousLevelsFilled = formData.onboarding_document_details.every((item) => {
      return item.joining_date && item.letter_type && item.processed_by && item.approved_by && item.letter_status && item.comments;
    });

    let isDuplicate = checkForOnboardDocumentDuplicates(formData.onboarding_document_details);
    if (allPreviousLevelsFilled && isDuplicate === "") {
      const newItems = [...formData.onboarding_document_details,
      { joining_date: "", letter_type: "", approved_by: "", processed_by: "", approved_by: "", letter_status: "", send_mail: "", comments: "" },];
      updateWholeFormData("onboarding_document_details", newItems);
    } else {
      ToastMsg("error", isDuplicate ? isDuplicate : "Please fill all previous levels before adding a new one.");
    }
  };

  const handleRemove = (index) => {
    const newItems = [...formData.onboarding_document_details];
    newItems.splice(index, 1);
    updateWholeFormData("onboarding_document_details", newItems);
  };

  const handleInputChange = (statekey, name, value) => {
    form.setFields([
      {
        name: [statekey, name],
        value: value ? value : "",
      }
    ]);
    triggerFieldValidation(statekey, name);
    updateFormData(statekey, name, value);
  };

  const triggerFieldValidation = (statekey, fieldName) => {
    form.validateFields([[statekey, fieldName]]);
  };

  const extractFileDetails = (file) => {
    if (typeof file === "string") {
      // URL-based file
      const fileName = file.split('/').pop().split('?')[0];
      const format = fileName.split('.').pop().toLowerCase();
      return { name: fileName, format, size: null };
    } else if (file.originFileObj) {
      // Object-based file with originFileObj
      return { name: file.name, format: file.type.split('/').pop().toLowerCase(), size: file.size };
    }
    return { name: '', format: '', size: null };;
  };

  const checkForDuplicateFiles = (newFile) => {
    if (typeof newFile === "object" && newFile.originFileObj) {
      const newFileDetails = extractFileDetails(newFile);

      return existingFiles.some(existingFile => {
        if (existingFile) {
          const existingFileDetails = extractFileDetails(existingFile);
          return (
            newFileDetails.name === existingFileDetails.name &&
            newFileDetails.format === existingFileDetails.format &&
            newFileDetails.size === existingFileDetails.size
          );
        }
        return false;
      });
    }
    return false;
  };

  const handleFileChange = (statekey, fieldName, fileList) => {
    if (Array.isArray(fileList) && fileList.length > 0) {
      // Check for duplicates
      const isDuplicate = checkForDuplicateFiles(fileList[0]);

      if (isDuplicate) {
        ToastMsg("error", "Duplicate file detected. Please upload a different file.");
        return;
      }
    }

    // Proceed to update form fields and state if no duplicates
    form.setFields([
      {
        name: [statekey, fieldName],
        value: fileList ? fileList : "",
      }
    ]);
    updateFormData(statekey, fieldName, fileList);
  };

  useEffect(() => {
    if (preview.type === 'pdf' && preview.content) {
      window.open(preview.content, '_blank');
      setPreview({ visible: false, content: null, type: '' });
    }
  }, [preview]);

  const handlePreview = (fileList) => {
    const fileListType = (fileList && Array.isArray(fileList))
      ? "array"
      : (fileList && typeof fileList === "string")
        ? "string"
        : "unknown";

    if (fileListType === "array" && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      // Check if the file is an image or PDF
      if (file && file instanceof File) {
        const fileExtension = file.type.startsWith('image/') ? 'image' : 'pdf';
        setPreview({
          visible: fileExtension === 'image',
          content: URL.createObjectURL(file),
          type: fileExtension,
          contentType: fileListType,
        });
      }
    } else if (fileListType === "string" && fileList) {
      const fileExtension = extractFileExtension(fileList) === "pdf" ? 'pdf' : 'image';
      setPreview({
        visible: fileExtension === "image",
        content: fileList,
        type: fileExtension,
        contentType: fileListType,
      });
    }
  };

  const handleCancelPreview = () => {
    setPreview({
      visible: false,
      content: null,
      type: '',
    });
  };

  const handleOnboardingDocsChange = (name, index, value) => {
    let newItems = [...formData.onboarding_document_details];
    newItems[index][name] = value;
    if (name === "document" && Array.isArray(value) && value.length > 0) {
      // Check for duplicates
      const isDuplicate = checkForDuplicateFiles(value[0]);
      if (isDuplicate) {
        ToastMsg("error", "Duplicate file detected. Please upload a different file.");
        return;
      }
    }
    updateWholeFormData("onboarding_document_details", newItems);
    form.setFields([
      {
        name: ['onboarding_document_details', index, name],
        value: name === "send_mail" ? value === true ? "True" : "False" : value,
        errors: undefined,
      }
    ]);
  };

  const handleFinish = (values) => {
    const duplicateMsg = values?.onboarding_document_details?.length >= 2 ? checkForOnboardDocumentDuplicates(values.onboarding_document_details) : "";
    if (duplicateMsg !== "") {
      ToastMsg("error", duplicateMsg);
      return;
    } else {
      onSave(false, false);
    }
  }

  const handleClick = (e) => {
    setIsDisable(true)
    handleSubmit(e, false, true)
  }

  const handleSubmit = (e, send_mail = false, move_to_employee = false) => {
    e.preventDefault();

    form.validateFields()
      .then((values) => {
        const duplicateMsg = values?.onboarding_document_details?.length >= 2 ? checkForOnboardDocumentDuplicates(values.onboarding_document_details) : "";
        if (duplicateMsg !== "") {
          ToastMsg("error", duplicateMsg);
          setIsDisable(false)
          return;
        } else {
          onSave(send_mail, move_to_employee);
        }
      }).catch((errorInfo) => {
        ToastMsg("error", "Form validation failed. Please check the required fields.");
        setIsDisable(false)
      });
  }

  const renderSelect = (label, statekey, name, list = [], showKey = "text", valueKey = "value", required = false) => {
    if (!hideCursorRef.current[statekey]) {
      hideCursorRef.current[statekey] = React.createRef();
    }
    return (
      <div>
        <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
        <Select
          showSearch
          value={formData[statekey][name] ? formData[statekey][name] : ""}
          name={name}
          onChange={(value) => handleInputChange(statekey, name, value)}
          filterOption={(input, option) => {
            const childrenText = option.children;
            return typeof childrenText === 'string' &&
                childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          disabled={!isEditAccess}
          ref={hideCursorRef.current[statekey]}
          onSelect={() => {
            hideCursorRef.current[statekey].current.blur();
          }}
        >
          {list?.length > 0 && list.map((option) => (
            ((valueKey === "id" && (option.is_active === true || option.id === formData[statekey][name])) || valueKey !== "id") ?
              <Select.Option key={option[valueKey]} value={option[valueKey]}>
                {name === "document_verified_by" || name === 'background_verified_by' ?
                  `${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""}`
                  : option[showKey]}
              </Select.Option> : null
          ))}
        </Select>
      </div>
    )
  };

  const handleDateChange = (dateString, statekey, name) => {
    updateFormData(statekey, name, dateString);
    form.setFields([
      {
        name: [statekey, name],
        value: dateString ? dateString : undefined,
        errors: undefined,
      }
    ]);
  };

  const renderDatePicker = (label, statekey, name, disabledDates = disabledPastDate, required = false) => {
    return (
      <>
        <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
        <DatePicker
          disabled={!isEditAccess}
          allowClear
          inputReadOnly={true}
          locale={locale}
          style={{ width: "100%" }}
          name={name}
          format={"DD-MM-YYYY"}
          value={formData[statekey][name] ? dayjs(formData[statekey][name]) : undefined}
          onChange={(date) => handleDateChange(date, statekey, name)}
          disabledDate={disabledDates}
          placeholder=""
        />
      </>
    )
  }


  const renderTextArea = (label, statekey, name, required = false, placeholder = "") => {
    return (
      <>
        <div className='commonInputTlt'>{label} {required ? <span className='requiredTxt'>*</span> : null}</div>
        <Input.TextArea
          bordered="true"
          className="mt-2 textarea"
          rows={4}
          autoSize={{
            minRows: 2,
            maxRows: 5,
          }}
          maxLength={150}
          value={formData[statekey][name] ? formData[statekey][name] : ""}
          placeholder={`Comments about the ${placeholder}`}
          name="description"
          onChange={(e) => handleInputChange(statekey, name, e.target.value)}
          disabled={!isEditAccess}
        />
      </>);
  }

  const beforeUpload = (file) => {
    const fileSizeMB = file.size / (1024 * 1024);
    const isWordDocument = file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    if (!isWordDocument) {
      ToastMsg("error", "Only Pdf & Word documents are supported");
      return false;
    }
    if (fileSizeMB > imgUploadSize) {
      ToastMsg("error", `File size should be less than ${imgUploadSize}MB`);
      return false;
    }
    return true;
  };

  const disableSendMailBtn = () => {
    const hasSendMailTrue = formData.onboarding_document_details.some(
      item => item.send_mail === true
    );
    if (hasSendMailTrue) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <Form
      layout="vertical"
      name="basicform"
      ref={formRef}
      style={{ width: "100%", height: "100%" }}
      requiredMark={false}
      onFinish={handleFinish}
      initialValues={formData}
      onSubmit={(e) => e.preventDefault()}
      form={form}
    >
      <div className="grid grid-cols-1" style={{ height: "92%", overflowY: "auto" }}>
        {isScrLoading && (
          <div className="loaderOverlay">
            <Spin />
          </div>
        )}
        <Card>
          <div className="divide-y divide-gray-400 md:divide-y-8">
            <p id="common_weekoffTxt" style={{ textAlign: "left", borderBottom: "1px solid #cbcbcb", paddingBottom: '1dvh' }}>Onboarding</p>
          </div>
          <p id="common_weekoffTxt" style={{ textAlign: "left" }}>Onboarding Process Details</p>
          <div class="grid grid-cols-3 gap-4">

            <div className="text-left">
              <Form.Item
                name={["onboarding_process_details", "document_verification_date"]}
                rules={[{ required: true, message: "This field is required" },]}
              >
                {renderDatePicker("Document Verification date", "onboarding_process_details", "document_verification_date", disabledPastDate, true)}
              </Form.Item>
            </div>

            <div className="text-left">
              <Form.Item
                name={["onboarding_process_details", "document_verification_status"]}
                rules={[{ required: true, message: "This field is required" },]}
              >
                {renderSelect("Document Verification Status", "onboarding_process_details", "document_verification_status", verifyStatusEnum, 'text', 'value', true)}
              </Form.Item>
            </div>

            <div className="text-left">
              <Form.Item
                name={["onboarding_process_details", "document_verified_by"]}
                rules={[{ required: true, message: "This field is required" },]}
              >
                {renderSelect("Verified By", "onboarding_process_details", "document_verified_by", empList, 'first_name', 'id', true)}
              </Form.Item>
            </div>

            <div className="text-left col-span-3">
              <Form.Item
                name={["onboarding_process_details", "document_verification_comments"]}
                rules={[{ required: true, message: "This field is required" },]}
              >
                {renderTextArea("Comments", "onboarding_process_details", "document_verification_comments", true, "document verification")}
              </Form.Item>
            </div>

            <div className="text-left">
              <Form.Item
                name={["onboarding_process_details", "background_verification_date"]}
                rules={[{ required: true, message: "This field is required" },]}
              >
                {renderDatePicker("Background Verification date", "onboarding_process_details", "background_verification_date", disabledPastDate, true)}
              </Form.Item>
            </div>

            <div className="text-left">
              <Form.Item
                name={["onboarding_process_details", "background_verification_status"]}
                rules={[{ required: true, message: "This field is required" },]}
              >
                {renderSelect("Background Verification Status", "onboarding_process_details", "background_verification_status", verifyStatusEnum, 'text', 'value', true)}
              </Form.Item>
            </div>

            <div className="text-left">
              <Form.Item
                name={["onboarding_process_details", "background_verified_by"]}
                rules={[{ required: true, message: "This field is required" },]}
              >
                {renderSelect("Verified By", "onboarding_process_details", "background_verified_by", empList, 'first_name', 'id', true)}
              </Form.Item>
            </div>

            <div className="text-left col-span-3">
              <Form.Item
                name={["onboarding_process_details", "background_verification_comments"]}
                rules={[{ required: true, message: "This field is required" },]}
              >
                {renderTextArea("Comments", "onboarding_process_details", "background_verification_comments", true, "background verification")}
              </Form.Item>
            </div>

          </div>
        </Card>
        <div className="pt-3">
          {<Card>
            <p id="common_weekoffTxt" className="pb-4 text-left">Onboarding Document Details</p>
            <div className="">
              <div className="grid">
                {formData?.onboarding_document_details?.map((item, index) => {
                  let ImageUrl = (!Array.isArray(item?.document) && (item.document && typeof item.document === "string"))
                    ? extractImageName(item?.document) : "";
                  let isDocAvail = item?.document && item?.document?.length > 0 && item?.document[0]?.name;
                  let fileName = ImageUrl || isDocAvail;
                  return (
                    <div key={index} className="grid grid-cols-3 gap-x-5">
                      <div className="flex-initial ... text-left">
                        <Form.Item
                          name={["onboarding_document_details", index, "joining_date"]}
                          rules={[{ required: true, message: "This field is required" },]}>
                          <div className='commonInputTlt'>Joining Date {<span className='requiredTxt'>*</span>}</div>
                          <DatePicker
                            allowClear
                            inputReadOnly={true}
                            locale={locale}
                            style={{ width: "100%" }}
                            name={"joining_date"}
                            format={"DD-MM-YYYY"}
                            value={item?.joining_date ? dayjs(item.joining_date) : undefined}
                            onChange={(date) => handleOnboardingDocsChange('joining_date', index, date)}
                            disabledDate={disabledPastDate}
                            placeholder=""
                            disabled={!isEditAccess}
                          />
                        </Form.Item>
                      </div>
                      <div className="flex-initial ... text-left">
                        <Form.Item name={["onboarding_document_details", index, "letter_type"]}
                          rules={[{ required: true, message: "This field is required" },]}>
                          <div className="commonInputTlt">Letter Type  {<span className='requiredTxt'>*</span>}</div>
                          <Select
                            disabled={!isEditAccess}
                            showSearch
                            value={item.letter_type ? item.letter_type : ""}
                            onChange={(value) => handleOnboardingDocsChange("letter_type", index, value)}
                            allowClear={true}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            ref={(el) => {
                              hideCursorRef.current[index] = el;
                            }}
                            onSelect={() => {
                              if (hideCursorRef.current[index]) {
                                hideCursorRef.current[index].blur();
                              }
                            }}
                          >
                            {letterTypeApiList.map(option => {
                              return (
                                <Select.Option key={option.id} value={option.id}>
                                  {option.letter_type_name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="flex-initial ... text-left">
                        <Form.Item name={["onboarding_document_details", index, "processed_by"]}
                          rules={[{ required: true, message: "This field is required" },]}>
                          <div className="commonInputTlt">Processed By  {<span className='requiredTxt'>*</span>}</div>
                          <Select
                            disabled={!isEditAccess}
                            showSearch
                            value={item.processed_by ? item.processed_by : ""}
                            onChange={(value) => handleOnboardingDocsChange("processed_by", index, value)}
                            allowClear={true}
                            filterOption={(input, option) => {
                              const childrenText = option.children;
                              return typeof childrenText === 'string' &&
                                  childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            ref={(el) => {
                              hideCursorRef.current[index] = el;
                            }}
                            onSelect={() => {
                              if (hideCursorRef.current[index]) {
                                hideCursorRef.current[index].blur();
                              }
                            }}
                          >
                            {empList.map((option) => (
                              (option.is_active === true || option.id === item?.processed_by) ?
                                <Select.Option key={option.id} value={option.id}>
                                 {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                </Select.Option> : null
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="flex-initial ... text-left">
                        <Form.Item name={["onboarding_document_details", index, "approved_by"]}
                          rules={[{ required: true, message: "This field is required" },]}
                        >
                          <div className="commonInputTlt">Approved By  {<span className='requiredTxt'>*</span>}</div>
                          <Select
                            disabled={!isEditAccess}
                            showSearch
                            value={item.approved_by ? item.approved_by : ""}
                            onChange={(value) => handleOnboardingDocsChange("approved_by", index, value)}
                            allowClear={true}
                            filterOption={(input, option) => {
                              const childrenText = option.children;
                              return typeof childrenText === 'string' &&
                                  childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            ref={(el) => {
                              hideCursorRef.current[index] = el;
                            }}
                            onSelect={() => {
                              if (hideCursorRef.current[index]) {
                                hideCursorRef.current[index].blur();
                              }
                            }}
                          >
                            {empList.map((option) => (
                              (option.is_active === true || option.id === item.processed_by) ?
                                <Select.Option key={option.id} value={option.id}>
                                  {`${option.first_name ? option.first_name : ""} ${option.last_name ? option.last_name : ""} ${option.employee_id ? " - " + option.employee_id : ""} `}
                                </Select.Option> : null
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="flex-initial ... text-left">
                        <Form.Item name={["onboarding_document_details", index, "letter_status"]}
                          rules={[{ required: true, message: "This field is required" },]}
                        >
                          <div className="commonInputTlt">Letter Status {<span className='requiredTxt'>*</span>}</div>
                          <Select
                            disabled={!isEditAccess}
                            showSearch
                            value={item.letter_status ? item.letter_status : ""}
                            onChange={(value) => handleOnboardingDocsChange("letter_status", index, value)}
                            allowClear={true}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            ref={(el) => {
                              hideCursorRef.current[index] = el;
                            }}
                            onSelect={() => {
                              if (hideCursorRef.current[index]) {
                                hideCursorRef.current[index].blur();
                              }
                            }}
                          >
                            {letterStatusEnum.map(option => {
                              return (
                                <Select.Option key={option.value} value={option.value}>
                                  {option.text}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="flex-initial ... text-left">
                        <Form.Item name={["onboarding_document_details", index, "send_mail"]} style={styles.formItem}>
                          <Checkbox
                            style={styles.textField}
                            checked={item.send_mail}
                            onChange={(e) => handleOnboardingDocsChange("send_mail", index, e.target.checked)}
                          >
                            Send Mail
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div className="flex-initial ... text-left col-span-3">
                        <Form.Item
                          name={["onboarding_document_details", index, "comments"]}
                          rules={[{ required: true, message: "This field is required" },]}
                        >
                          <div className='commonInputTlt'>Comments {<span className='requiredTxt'>*</span>}</div>
                          <Input.TextArea
                            bordered="true"
                            className="mt-2 textarea"
                            rows={4}
                            autoSize={{
                              minRows: 2,
                              maxRows: 5,
                            }}
                            maxLength={150}
                            value={item?.comments ? item.comments : ""}
                            placeholder={`Comments`}
                            name="description"
                            disabled={!isEditAccess}
                            onChange={(e) => handleOnboardingDocsChange('comments', index, e.target.value)}
                          />
                        </Form.Item>
                      </div>

                      <div className="flex-initial ... text-left col-span-2">
                        <p id="common_weekoffTxt">Upload Document</p>
                        <p className="commonInputTlt">Supported Format Pdf & Word</p>

                        <Form.Item
                          name={["onboarding_document_details", index, "document"]}
                          rules={[{ required: true, message: 'Please upload a document!' }]}
                        >
                          <div>
                            <Upload
                              accept=".pdf,.doc,.docx"
                              beforeUpload={beforeUpload}
                              onChange={({ fileList }) => handleOnboardingDocsChange('document', index, fileList)}
                              fileList={(Array.isArray(item?.document)) ? item?.document : []}
                              maxCount={1}
                              showUploadList={false}
                            >
                              <MyButton className="flex items-center"
                                icon={ImagePaths.Upload.default}
                                htmlType="button"
                                label="Upload Dcouments"
                                paddingX={"1vw 1vw"}
                                testID="doc_upload" />
                            </Upload>
                          </div>
                        </Form.Item>
                      </div>

                      <div className="flex justify-end col-span-1">
                        {(index > 0 && isEditAccess) ? (
                          <Button
                            onClick={() => handleRemove(index)}
                            icon={<MinusCircleOutlined className="plusIcon" />}
                            style={{ border: 0 }}
                            size="large"
                          ></Button>
                        ) : null}
                      </div>
                      <div className="flex-initial ... text-left">
                        {fileName ?
                          (<div className="flex justify-between items-center p-2 my-2" style={styles.documentBorder}>
                            <div className="flex items-center">
                              <img src={ImagePaths.pdfOn.default} alt="pdf" className='admin_org_pdf' />
                              {fileName.length > 20 ?
                                <Tooltip title={fileName}>{<span style={styles.docTxt}>{"..." + fileName?.slice(-19)}</span>}</Tooltip>
                                : <p style={styles.docTxt}>{fileName ? fileName : "-"}</p>}
                            </div>
                            <IconButton
                              title="Download Pdf"
                              icon={<DownloadOutlined style={styles.downloadIcon} />}
                              onClick={(e) => {
                                e.preventDefault();
                                if (ImageUrl) {
                                  downloadPDF(item.document);
                                } else {
                                  let url = URL.createObjectURL(item.document[0].originFileObj)
                                  downloadPDF(url);
                                }
                              }}
                            />
                          </div>
                          ) : null}
                      </div>
                    </div>)
                })}
                {isEditAccess ? <div className="flex flex-row justify-start">
                  <Button
                    onClick={handleAdd}
                    icon={<PlusCircleOutlined className="plusIcon" />}
                    style={{ border: 0 }}
                    size="large"
                  ></Button>
                </div> : null}
              </div>
            </div>
          </Card>}
        </div>
      </div>
      <div className='flex justify-center items-center' style={{ height: "8%", backgroundColor: "white" }}>
        <MyButton htmlType="button" label="Back" onClick={() => OnBack("fromOnboard")} outlined={true} paddingX={"0 2vw"} marginRight={"0.625vw"} />
        <MyButton htmlType="submit" label={"Save"} loading={isLoading} paddingX={"0 2vw"} marginRight={"0.625vw"} />
        <MyButton htmlType="sendmail" label={"Send Email"} loading={isLoading} paddingX={"0 2vw"} marginRight={"0.625vw"} onClick={(e) => handleSubmit(e, true, false)} disabled={disableSendMailBtn()} />
        <MyButton htmlType="movetoemployee" label={"Move to Employee"} disabled={disabled} loading={isLoading} paddingX={"0 2vw"} onClick={(e) => handleClick(e)} />

      </div>
      {/* Preview Modal */}
      {
        (preview?.visible && preview?.content && preview?.type === 'image') ?
          <Modal
            open={preview.visible}
            centered
            className="custom-modal"
            width={"50dvw"}
            footer={null}
            onCancel={handleCancelPreview}>
            <img src={preview.content} alt="Preview" className="emp_img" />
          </Modal>
          : null
      }
    </Form>
  );
};

const styles = {
  uploadIcon: {
    width: "1.2vw",
    height: "1.2vw",
    marginRight: "0.5vw",
    filter: 'invert(100%) brightness(1000%)'
  },
  formItem: {
    margin: "1vw"
  },
  documentBorder: {
    border: "1px solid #004B49",
    height: "6vh",
  },
  downloadIcon: {
    color: "#04B7B1",
    fontSize: "1.3vw",
    fontWeight: "bold"
  },
  docTxt: {
    color: "#183433",
    fontSize: '0.8vw',
    fontFamily: "Urbanist",
    fontWeight: "500",
    marginBottom: "0.3vw",
  },
}

export default EmpOnboardingFinalStep
