import { Checkbox } from "antd";
import { EmployeeDetails } from "./CommonComponent";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useMemo } from "react";

export const EmployeeList = ({
  renderRotationalList,
  formData,
  editAccess,
  handleEmpSltChange,
  handleDepartmentSelectChange,
  handleDepartSltChange,
  renderShiftButtons,
}) => {
  // Combine all list items into one array and flatten groups
  const listItems = useMemo(() => {
    return renderRotationalList.flatMap((item) => {
      if (formData?.groupBy === "department" && item?.employees?.length > 0) {
        return [
          { type: "department", ...item },
          ...item.employees.map((employeeData) => ({
            type: "employee",
            departmentId: item?.department?.id,
            ...employeeData,
          })),
        ];
      } else if (formData?.groupBy === "team" && item?.employees?.length > 0) {
        return [
          { type: "team", ...item },
          ...item.employees.map((employeeData) => ({
            type: "employee",
            teamId: item?.team?.id,
            ...employeeData,
          })),
        ];
      } else if (formData?.groupBy === "employee") {
        return [{ type: "employee", ...item }];
      }
      return [];
    });
  }, [renderRotationalList, formData?.groupBy]);

  // Define item size for react-window
  const itemSize = 80; // Fixed size, can be adjusted for headers and employees
  const itemCount = listItems.length;

  // Row renderer function
  const Row = ({ index, style }) => {
    const item = listItems[index];

    const adjustedTop =
      index === 0
        ? style.top // Use the original top value for the first row
        : `calc(${style.top}px - 10px)`; // Slight adjustment for other rows

    if (item.type === "department" || item.type === "team") {
      return (
        <div
          style={{ ...style, height: "65px" }}
          className="mb-1 rostering_groupby_txt"
        >
          {/* Checkbox for department or team */}
          <Checkbox
            disabled={!editAccess}
            className="custom-department-checkbox"
            checked={item?.is_department_selected}
            onChange={(e) =>
              handleDepartmentSelectChange(
                item?.department?.id || item?.team?.id,
                e.target.checked
              )
            }
          >
            <span className="musterStatusTxt" style={{ fontWeight: 600 }}>
              {item?.department?.name || item?.team?.name || ""}
            </span>
          </Checkbox>
        </div>
      );
    }

    if (item.type === "employee") {
      return (
        <div
          style={{ ...style, top: adjustedTop }}
          className="flex items-center border-b p-1 mb-1 rostering_employee"
        >
          {/* Checkbox for employee */}
          <Checkbox
            disabled={!editAccess}
            className="h-full flex items-center custom-roster-checkbox"
            style={{ width: "14dvw" }}
            checked={item?.employee?.is_emp_selected}
            onChange={(e) => {
              // Ensure appropriate handler is called based on the context
              if (item.departmentId || item.teamId) {
                // Call department or team handler
                handleDepartSltChange(item?.employee?.id, e.target.checked);
              } else {
                // Call employee selection handler
                handleEmpSltChange(item?.employee?.id, e.target.checked);
              }
            }}
          >
            <span>
              <EmployeeDetails
                details={{
                  ...item.employee,
                  title: item?.employee?.title || "",
                  profile: item?.employee?.profile || "",
                  designation_id: item?.employee?.designation
                    ? {
                        id: item?.employee?.designation.id,
                        designation_name: item?.employee?.designation.name,
                      }
                    : { id: 0, designation_name: "" },
                  first_name: item?.employee?.employee_name || "",
                  id: item?.employee?.employee_id || "",
                }}
              />
            </span>
          </Checkbox>

          {/* Shift buttons for employee */}
          {renderShiftButtons(item, item.departmentId || item.teamId)}
        </div>
      );
    }

    return null; // Ensure we return null for any unhandled cases
  };

  return (
    <>
      {/* Render virtualized list with AutoSizer for full-size responsiveness */}
      {/* <AutoSizer>
        {({ height, width }) => (
        )}
      </AutoSizer> */}
      <List height={325} itemCount={itemCount} itemSize={itemSize} width="100%">
        {Row}
      </List>
    </>
  );
};
